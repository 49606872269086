import React, { useState } from 'react';
import { Card, Container } from 'reactstrap';
import { push, ref, getDatabase } from 'firebase/database';
import emailjs from 'emailjs-com';
import { publicKey, serviceID, templateID } from '../../api/emailjs';

export default function Register() {
    const [course,setCourse] = useState('Front End Web Development');
    const [classOption,setClassOption] = useState('Group class');
    const [firstName,setFirstName] = useState('');
    const [lastName,setLastName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [weekdays,setWeekdays] = useState('');

    let date = new Date().toString();
    

    function handleSubmit(e){
        e.preventDefault();
        let newStudent = {
            firstName,
            lastName,
            email,
            phone,
            classOption,
            date,
            weekdays,
            course
        }
        push(ref(getDatabase(),`enrollment`),newStudent);

        if (classOption == 'Group class') {
            window.open(`https://buy.stripe.com/9AQ3cAgxzf1uc9OfYY`)
        } else if (classOption == 'Private class') {
            window.open(`https://buy.stripe.com/3cseVi3KNaLec9O9AE`)
        } else if(classOption == 'Interview preparation'){
            window.open(`https://buy.stripe.com/bIY28w4ORaLe2ze3cf`)
        }else {
            alert('You did not select class option. Check info and try again')
        }

        // send email
        emailjs.init(publicKey)
        emailjs.sendForm(serviceID,templateID,e.target)

    }

  return (
   <>
   <Container>
    <br />
        <h1>Class Enrollment</h1>
        <br />
      <Card style={{padding:20}}>
      <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="FirstName">First Name </label>
                <input name='from_name' type="text" className="form-control" onChange={(e) => setFirstName(e.target.value)} required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="LastName">Last Name </label>
                <input name='from_name' type="text" className="form-control" onChange={(e) => setLastName(e.target.value)} required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="Email">Email </label>
                <input name='message' type="text" className="form-control" onChange={(e) => setEmail(e.target.value)} required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="Phone">Phone </label>
                <input name='message' type="text" className="form-control" onChange={(e) => setPhone(e.target.value)} required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="Option">What course are you interested in studying? </label>
                <select
                onChange={(e) => setCourse(e.target.value)}
                name="" id="" className='form-control'>
                    <option value="Front End Web Development">Front End Web Development</option>
                    <option value="Full Stack Development">Full Stack Development</option>
                    <option value="Software Engineering">Software Engineering</option>
                    <option value="Mobile Application Development">Mobile Application Development</option>
                </select>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="Option">Choose A Class Option </label>
                <select
                onChange={(e) => setClassOption(e.target.value)}
                name="" id="" className='form-control'>
                    <option value="Group class">Group Class</option>
                    <option value="Private class">Individual Class</option>
                    <option value="Interview preparation">Interview preparation</option>
                </select>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="price">Monthly Price</label>
                {
                    classOption == 'Group class' && <p>$1000</p>
                }
                {
                    classOption == 'Private class' && <p>$2000</p>
                }
                 {
                    classOption == 'Interview preparation' && <p>$2500</p>
                }
            </div>

           {classOption === 'Private class' && <div className="form-group mb-3">
                <label htmlFor="Option">Choose a class schedule </label>
                <select
                onChange={(e) => setWeekdays(e.target.value)}
                name="" id="" className='form-control'>
                    <option value="Monday and Wednesday at 10am - 12pm">Monday and Wednesday at 10am - 12pm</option>
                    <option value="Monday and Wednesday at 6pm - 8pm">Monday and Wednesday at 6pm - 8pm</option>
                    <option value="Tuesday and Thursday at 2pm - 4pm">Tuesday and Thursday at 2pm - 4pm</option>
                    <option value="Tuesday and Thursday at 4pm - 6pm">Tuesday and Thursday at 4pm - 6pm</option>
                </select>
            </div> 
            }

            {classOption === 'Group class' &&
                 <div className="form-group mb-3">
                 <label htmlFor="Option">Choose a class schedule </label>
                 <select
                 onChange={(e) => setWeekdays(e.target.value)}
                 name="" id="" className='form-control'>
                     <option value="Monday and Wednesday at 12pm - 3pm">Monday and Wednesday at 12pm - 3pm</option>
                     <option value="Tuesday and Thursday at 10am - 1pm">Tuesday and Thursday at 10am - 1pm</option>
                 </select>
             </div>
            }

{classOption === 'Interview preparation' &&
                 <div className="form-group mb-3">
                 <label htmlFor="Option">Choose a class schedule </label>
                 <select
                 onChange={(e) => setWeekdays(e.target.value)}
                 name="" id="" className='form-control'>
                     <option value="Monday and Wednesday at 3pm - 5pm">Monday and Wednesday at 3pm - 5pm</option>
                     <option value="Tuesday and Friday at 6pm - 8pm">Tuesday and Thursday at 6pm - 8pm</option>
                 </select>
             </div>
            }

            <div className="form-group mb-3">
                <button className='btn btn-primary'>Continue</button>
            </div>
        </form>
      </Card>
    </Container>
   </>
  )
}
