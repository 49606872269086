import timg1 from '../images/team/img-1.jpg'
import timg2 from '../images/team/img-2.jpg'
import timg3 from '../images/team/img-3.jpg'
import timg4 from '../images/team/img-4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Morkal Akunda',
        slug:'Morkal-Akunda',
        title:'Web Designer',
        phone:'+1-845-750-1454',
        email:'ceo@merncode.com',
        address:'New York, NY',
        experience:'14 years',
        about:'I have been working as a Front End Web Developer and Designer since 2008. I used to work at tech companies, such as Google, Facebook, IBM and Delta Airlines.'
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Tiptop Jonathon',
        slug:'Tiptop-Jonathon',
        title:'Front End Developer',
        phone:'+1-845-554-6628',
        email:'admin@merncode.com',
        address:'New York, NY',
        experience:'10 years',
          about:'I have been working as a Full Stack Web and Mobile Application Developer and Designer since 2012. I used to work at tech companies, such as PayPal, Amazon, IBM and United Airlines.'
    },
    {
        Id: '3',
        tImg:timg3,
        name:'Aleon Dela',
        slug:'Aleon-Dela',
        title:'UX Designer',
        phone:'+1-347-622-3609',
        email:'admin@merncode.com',
        address:'New York, NY',
        experience:'9 years',
        about:'I have been working as an UX and UI Designer since 2014. I used to work at tech companies, such as Stripe, Amazon, Salesforce and other companies.'
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Eldor Togaymurotov',
        slug:'Beila-kelar',
        title:'Software Engineer',
        phone:'+1-929-420-0951',
        email:'eldorcodes@gmail.com',
        address:'Brooklyn, NY',
        experience:'7 years',
        about:'I have been working as a MERN Stack Engineer since 2018. I used to work at tech companies, such as Stripe, Mobile Enterprises LLC and other tech companies.'
    }
]

export default Teams;