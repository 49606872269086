import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Team from '../../api/team'
import Logo from '../../images/logo-2.png'

const TeamSinglePage = (props) => {
    const { slug } = useParams()
    const [successMessage,setSuccessMessage] = useState('');

    const TeamDetails = Team.find(item => item.slug === slug)

    const SubmitHandler = (e) => {
        e.preventDefault();
        setSuccessMessage('Message has been sent. Thank you')
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <Fragment>
            <Navbar hclass={'header-style-3'} Logo={Logo} />
            <PageTitle pageTitle={TeamDetails.name} pagesub='team Single' />
            <section className="team-sigle-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-12">
                            <div className="team-single-sidebar">
                                <div className="widget team-widget">
                                    <h3>Our teams</h3>
                                    <ul>
                                        {Team.map((team, aitem) => (
                                            <li key={aitem}><Link onClick={ClickHandler} to={`/team-single/${team.slug}`}>{team.name}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-8 col-12">
                            <div className="team-single-content">
                                <div className="team">
                                    <div className="img-holder">
                                        <img src={TeamDetails.tImg} alt="" />
                                    </div>
                                    <div className="team-single-info">
                                        <div className="info">
                                            <h3>{TeamDetails.name}</h3>
                                            <span>{TeamDetails.title}</span>
                                            <ul>
                                                <li><i className="ti-mobile"></i><span>Phone: </span>{TeamDetails.phone}</li>
                                                <li><i className="ti-email"></i><span>Email: </span>{TeamDetails.email}</li>
                                                <li><i className="ti-timer"></i><span>Experience: </span>{TeamDetails.experience}</li>
                                                <li><i className="ti-location-pin"></i><span>Address: </span>{TeamDetails.address}</li>
                                            </ul>
                                        </div>
                                        <div className="social">
                                            <ul>
                                                <li><Link onClick={ClickHandler} to="/teams-single/Michel-Troat"><i className="ti-facebook"></i></Link></li>
                                                <li><Link onClick={ClickHandler} to="/teams-single/Michel-Troat"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link onClick={ClickHandler} to="/teams-single/Michel-Troat"><i className="ti-linkedin"></i></Link></li>
                                                <li><Link onClick={ClickHandler} to="/teams-single/Michel-Troat"><i className="ti-pinterest"></i></Link></li>
                                                <li><Link onClick={ClickHandler} to="/teams-single/Michel-Troat"><i className="ti-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-details">
                                    <h2>About Me</h2>
                                    <p>{TeamDetails.about}</p>
                                    <h2>Experience</h2>
                                    <p>Above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa who sat upright, raising a heavy fur muff that covered the whole of her lower arm towards the viewer. </p>
                                    <h2>Education</h2>
                                    <p>Above it there hung a picture that he had recently cut out of an illustrated magazine and housed in a nice, gilded frame. It showed a lady fitted out with a fur hat and fur boa</p>
                                    <h2>Achievements</h2>
                                    <ul>
                                        <li>- Best Developer award 2017</li>
                                        <li>- Won 100 case in 2018</li>
                                        <li>- Specials awards for Web Components 2018</li>
                                        <li>- Super Engineers’ Top 50 Projects in USA</li>
                                    </ul>
                                    <h2>Skills</h2>
                                    <div className="skills">
                                        <div className="skill">
                                            <h6>Development</h6>
                                            <div className="progress">
                                                <div className="progress-bar"
                                                    role="progressbar" style={{ width: `85%` }}>
                                                </div>
                                                <span>85%</span>
                                            </div>
                                        </div>
                                        <div className="skill">
                                            <h6>Testing</h6>
                                            <div className="progress">
                                                <div className="progress-bar"
                                                    role="progressbar" style={{ width: `95%` }}>
                                                </div>
                                                <span>95%</span>
                                            </div>
                                        </div>
                                        <div className="skill">
                                            <h6>Deployment</h6>
                                            <div className="progress">
                                                <div className="progress-bar"
                                                    role="progressbar" style={{ width: `92%` }}>
                                                </div>
                                                <span>92%</span>
                                            </div>
                                        </div>
                                        <div className="skill">
                                            <h6>Debugging</h6>
                                            <div className="progress">
                                                <div className="progress-bar"
                                                    role="progressbar" style={{ width: `95%` }}>
                                                </div>
                                                <span>95%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <h2>Contact Me</h2>
                                    <div className="contact-form">
                                        {successMessage ? <p>{successMessage}</p> : <form method="post" onSubmit={SubmitHandler}>
                                            <div>
                                                <input type="text" className="form-control" name="name" id="name" placeholder="Name*" required/>
                                            </div>
                                            <div>
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Email*" required/>
                                            </div>
                                            <div className="fullwidth">
                                                <textarea className="form-control" name="note" id="note" placeholder="Case Description..." required></textarea>
                                            </div>
                                            <div className="submit-area">
                                                <button type="submit" className="theme-btn">Submit It Now</button>
                                            </div>
                                        </form>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TeamSinglePage;
